import PageHeader from '@/components/PageHeader'
import tableMixin from '@/minxin/table-minxin'
import Adsearch from '@/components/Adsearch'
import root from '@/minxin/root'
const Index = {
  name: 'delivery-goods',
  components: {
    PageHeader,
    Adsearch
  },
  mixins: [tableMixin, root],
  data() {
    return {
      root_name: 'spck',
      root_msg: '商品出库',
      isShow: false,
      visible: false,
      adSearchForm: [],
      adSearchForm1: [
        { name: '交货单号', value: 'DeliveryId' },
        { name: '销售订单号', value: 'OrderId' },
        { name: '订货单位', value: 'BuyCompanyName' },
        { name: '送达单位', value: 'SendCompanyName' },
        { name: '订单时间', value: 'orderdate', date: true }
      ],
      adSearchForOthers: [
        { name: '出库单号', value: 'StockOutId' },
        { name: '操作人', value: 'Operater' },
        { name: '物流单号', value: 'Logistics' },
        { name: '出库时间', value: 'outdate', date: true }
      ],
      formSearch: {
        SelectType: 1,
        KeyWords: '',
        DeliveryId: '',
        StockOutId: '',
        OrderId: '',
        OrderStartDate: '',
        OrderEndDate: '',
        BuyCompanyName: '',
        SendCompanyName: '',
        Operater: '',
        Logistics: '',
        DeliveryStartDate: '',
        DeliveryEndDate: '',
        outdate: [],
        orderdate: []
      },
      visibleWL: false,
      form: {
        resource: 6,
        LogisticsName: '',
        LogisticsCode: '',
        IDX: 0
      },
      selects: [],
      WLinfo: [],
      wlForm: {},
      printTable: [],
      nameWL: ''
    }
  },
  beforeUpdate() {
    this.$nextTick(() => { // 在数据加载完，重新渲染表格
      this.$refs.multipleTable.doLayout()
    })
  },
  mounted() {
    this.$refs.multipleTable.bodyWrapper.addEventListener('scroll', (e) => {
      this.isScorllWidth = this.$refs.multipleTable.bodyWrapper.scrollLeft
    })
  },
  methods: {
    // 获取物流明细
    GetExpressInfoByStockOutID(StockOutID) {
      this.$api.GetExpressInfoByStockOutID({
        StockOutID
      }).then(res => {
        console.log('row物流明细', res)
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        // this.selects = res.Message
        if (res.Message.length >= 1) {
          this.nameWL = res.Message[0].LogisticsName
          this.form.LogisticsCode = res.Message[0].LogisticsCode
          this.form.IDX = res.Message[0].IDX
        }
        this.selects = res.Message.filter((item, index) => index !== 0)
      })
    },
    // 查看物流
    WLshow(row) {
      this.form.IDX = 0
      console.log(row) // DeliveryId
      this.GetExpressInfoByStockOutID(row.StockOutId)
      this.wlForm.DeliveryId = row.DeliveryId
      this.wlForm.StockOutId = row.StockOutId
      this.visibleWL = true
      this.nameWL = ''
      this.form.resource = 6
      this.form.LogisticsName = ''
      this.form.LogisticsCode = ''
      this.selects = []
    },
    // 获取物流信息
    ExpressCompanyInfoLst() {
      this.$api.ExpressCompanyInfoLst().then(res => {
        console.log(res)
        this.WLinfo = res.Message
      })
    },
    addCode() {
      this.selects.push({
        LogisticsName: '',
        IDX: 0
      })
    },
    // 删除单号
    delCode(index) {
      this.selects.splice(index, 1)
    },
    // 处理物流数据
    setWLdata() {
      const data = []
      const arr = this.selects.filter(item => item.LogisticsCode)
      arr.map(item => {
        data.push({
          IDX: item.IDX, // 物流信息idx
          LogisticsName: this.form.LogisticsName,
          LogisticsCode: item.LogisticsCode
        })
      })
      data.unshift({
        IDX: this.form.IDX, // 物流信息idx
        LogisticsName: this.form.LogisticsName,
        LogisticsCode: this.form.LogisticsCode
      })
      return data
    },
    // 物流信息确认
    WLadd() {
      if (this.form.resource !== 6) {
        this.visibleWL = false
        return this.$message.info('你选择了自配送')
      }
      this.form.LogisticsName = this.nameWL
      if (!this.form.LogisticsName) return this.$message.error('请填写物流公司')
      if (!this.form.LogisticsCode) return this.$message.error('请填写物流单号')
      this.$api.SaveStockOutExpress({
        StockOutId: this.wlForm.StockOutId, // .StockOutId,
        DeliveryID: this.wlForm.DeliveryId,
        stockOutLogisticsInfos: this.setWLdata()
      }).then(res => {
        this.visibleWL = false
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.$message.success('保存成功')
        this.formSearch.SelectType = 2
        this.selectType(2)
      })
    },
    viewOrder(row) {
      row.SelectType = this.formSearch.SelectType
      console.log('dasa', row)
      return this.$router.push({ name: 'view-ordera', params: { row }})
      // if (this.formSearch.SelectType === 1) {
      //   return this.$router.push({ name: 'view-order', params: row, SelectType:this.formSearch.SelectType  })
      /**
       */
      // }
      // return this.$router.push({ name: 'delivery-fwarehouse', params: { StockOutType: 0, StockOutId: row.StockOutId }})
    },
    viewDetails(row, status) {
      this.$router.push({
        name: 'DeliveryDetails',
        params: {
          DeliveryId: row.DeliveryId || 0,
          StockOutID: row.StockOutId || 0
        }
      })
    },
    // 打印
    printTableA(Details) {
      var title = ''
      var divElements = ''
      // console.log(divElements)
      // // 构造一个表格
      let str_tr = ''
      let str_td = ''
      let num = 0
      const arr12 = [{
        label: '物料编号',
        prop: 'InvCode'
      }, {
        label: '物料名称',
        prop: 'InvName'
      }, {
        label: '品牌',
        prop: 'BrandName'
      }, {
        label: '物料类型',
        prop: 'MaterialSubTypeName'
      }, {
        label: '规格',
        prop: 'Specification'
      }, {
        label: '单位',
        prop: 'StockUnitN'
      }, {
        label: '批号/序列号',
        prop: 'LotNum'
      }, {
        label: '有效期截至',
        prop: 'ExpiredDate'
      }, {
        label: '订单数量',
        prop: 'OutNum'
      }, {
        label: '出库数量',
        prop: 'OutNum'
      }, {
        label: 'UDI码',
        prop: 'DINum'
      }, {
        label: '运输条件',
        prop: 'StorageConditionName'
      }, {
        label: '仓库',
        prop: 'StockName'
      }]
      arr12.map(item => {
        num += 1
        str_tr += `
                <th style="font-size: 12px;font-weight:400;min-width:100px" >${item.label}</th>
            `
      })
      Details.map((item, index) => {
        console.log('item', item)
        arr12.map((item1, index1) => {
          console.log('item1', item1)
          str_td += `<td  id="testTd" style="text-align:center;border:solid #e5e5e5 1px;font-size: 12px">${item[item1.prop] ? item[item1.prop] : ''}</td>]`
        })
      })
      const testTD = str_td.split(']')
      testTD.splice(str_td.split(']').length - 1, 1)
      // console.log('str_td', testTD)
      // console.log('num', num)
      let arr = []
      let str = ''
      arr = this.group(testTD, num)
      // console.log(arr)
      arr.map((item, index) => {
        str += `<tr>${item.join('')}</tr>`
      })
      const table_ = `
              <table border="1" class='table-0' id="dasasasdsad" cellpadding="0" cellspacing="0" style="border-collapse:collapse;border:none;width:100%">
              <tr>
                ${str_tr}
              </tr>
              ${str}
            </table>
          `
      // console.log(table_)
      var printWindow = window.open(Math.random() * 1000, '_blank', '')

      divElements = `<div class='div-0'> ${table_}</div>`
      printWindow.document.open()
      printWindow.document.write('<html><head><meta http-equiv="Pragma" content="no-cache" /><meta http-equiv="Cache-Control" content="no-cache" /><meta http-equiv="Expires" content="0" /><title>' + title + '</title>')
      printWindow.document.write(`<style type="text/css">
           html, body {
             font-size: 12px;
             margin: 0;
           }
          .p-lr-20 {padding: 0 20px; }
          .m-bottom-20 {margin-bottom: 20px; }
          .weight { font-weight: 700 }
          .f14 { font-size:12px}
          .f20 {font-size:12px}
          .flex {display:flex}
          .j-center {justify-content: center;}
          .column { flex-direction: column;}
          .a-center {align-items: center;}
          .f-warp { flex-wrap: wrap; }
          .j-start {justify-content: flex-start;}
          .m-tb-10 {margin: 10px 0;}
          .m-tb-20 {margin: 20px 0;}
          .j-between {justify-content: space-between;}
          .m-top-10 {margin-top: 10px }
          .element.style {width: auto; }
          .table_info {
            max-width: 1000px;
            margin: 0 auto;
          }
          table{table-layout: fixed;}
          td{word-break: break-all; word-wrap:break-word;}
          .div-0 {page-break-after: always}
          </style>`
      )
      printWindow.document.write('</head><body>')
      printWindow.document.write(divElements)
      printWindow.document.write('</body></html>')
      printWindow.document.write(`<script type="text/javascript">
          window.onload = function() {
            class Test {
              constructor() {
                this.windowHeight = null // 窗口高度
                this.pageIndex = 0 // 当前页
                this.height_print = '' // 初始高度
                this.max_height = null // 最大高度
                this.tbody_tr = '' // 获取表格子集
                this.splice_arr = [] // 被删掉的元素
                this.header_tr = null // 表头的tr 不改动
                this.table_main = null // 表格 父级
                this.table_main_height = 0 // 表格高度
                this.bottom_veiw_height = 0 // 表底高度
                this.top_veiw_height = 0 // 表头高度
                this.table_footer_height = 0 //  // 表格合计高度
                this.top_veiw_html = null // 表头的内容、
                this.bottom_veiw_html = null // 表底的内容
                this.title_view_html = null //  顶部标题dom
                this.table_footer_html_0 = null // 表格合计内容
                this.table_footer_html_1 = null // 表格合计内容
              }
              init() {
                this.windowHeight = window.screen.height
                 this.max_height =  Math.round(this.windowHeight  / 2)
                 console.log('this.max_height ',this.max_height )
                const t_b_view = document.getElementsByClassName('adsadasd1')
                this.table_main_height = document.getElementById('dasasasdsad').offsetHeight // 表格高度
                // this.table_main = document.getElementById('dasasasdsad') // 表格 父级
                this.table_main = document.getElementsByClassName('table-0')[ this.pageIndex] // 表格 父级
                // document.getElementsByClassName('div-0')[this.pageIndex].style.height =  this.max_height + 'px'
                // console.log( this.table_main.childNodes)
                this.tbody_tr = this.table_main.childNodes[1].children // 获取表格子集
                this.header_tr = this.tbody_tr[0].innerHTML // 表头的tr 不改动
                this.title_view_html = document.getElementsByClassName('header')[0].innerHTML // 顶部标题dom
                this.table_footer_height = (document.getElementsByClassName('table_footer')[0].offsetHeight) * 2 // 表格合计高度
                this.table_footer_html_0 = document.getElementsByClassName('table_footer')[0].innerHTML // 表格合计内容
                this.table_footer_html_1 = document.getElementsByClassName('table_footer')[1].innerHTML // 表格合计内容
                for (let i = 0; i < t_b_view.length; i++) {
                  this.top_veiw_height = t_b_view[0].offsetHeight
                  this.bottom_veiw_height = t_b_view[1].offsetHeight
                  this.top_veiw_html = t_b_view[0].innerHTML
                  this.bottom_veiw_html = t_b_view[1].innerHTML
                }
                this.heightA()
                this.isSet()
    
              }
          
              // 是否换页
              isSet() {
                if (this.height_print <= this.max_height) return // 不需要换页
                if (this.height_print > this.max_height) {
                   this.setData()
                }
              }
              // 处理换页的数据
              setData() {
                const arr = []
                for (let i = this.tbody_tr.length - 1; i >= 1; i--) {
                  if (this.height_print > this.max_height) {
                    // 将一个节点存起来
                    arr.push(this.tbody_tr[i])
                    // 删除一个节点
                    this.table_main.childNodes[1].removeChild(this.tbody_tr[i])
                    this.heightA()
                  }
                }
                this.tbody_tr = arr
                this.initDocument()
              }
          
              // 计算高度 当前页高度
              heightA() {
                   
                  this.height_print = document.getElementsByClassName('div-0')[this.pageIndex].offsetHeight
                  // document.getElementsByClassName('div-0')[0].style.background = 'red'
                  // console.log("最大高度",this.max_height)
                  // console.log('目前高度', this.height_print)
                // return this.height_print
              }
              // 为页面添加新的页
              initDocument() {
                this.pageIndex += 1
                let new_tr_td = ''
                for (let i = 0; i < this.tbody_tr.length; i++) {
                  new_tr_td += '<tr>' + this.tbody_tr[i].innerHTML + '</tr>'
                }
          
                // 新的表格
                const table_ = "<table border='1' class='table-0' id='dasasasdsad' cellpadding='0' cellspacing='0' style='border-collapse:collapse;border:none;width:100%'>" + this.header_tr + new_tr_td + '</table>'
                const dom_new = "<div class='div-0'><div class='header weight f20 flex j-center column a-center m-tb-20'>" + this.title_view_html + '</div>' + "<div class='flex f-warp adsadasd1 m-tb-10'>" + this.top_veiw_html + '</div>' + table_ + "<div class='flex p-lr-20 header table_footer' style='height: 25px; border-top: none; border-right: 1px solid rgb(235, 238, 245); border-bottom: 1px solid rgb(235, 238, 245); border-left: 1px solid rgb(235, 238, 245); border-image: initial; line-height: 25px;'>" + this.table_footer_html_0 + '</div>' + "<div class='flex p-lr-20 header table_footer' style='height: 25px; border-top: none; border-right: 1px solid rgb(235, 238, 245); border-bottom: 1px solid rgb(235, 238, 245); border-left: 1px solid rgb(235, 238, 245); border-image: initial; line-height: 25px;'>" + this.table_footer_html_1 + '</div>' + "<div class='flex f-warp adsadasd1 m-tb-10'>" + this.bottom_veiw_html + '</div> </div> '
                //   window.document.write(dom_new)
                const conentDiv = document.createElement('div')
                conentDiv.innerHTML = dom_new
                document.body.appendChild(conentDiv)
                this.init()
              }
            }
            setTimeout(() => {
                  const test = new Test()
            test.init()
            }, 60)
          
          }
          </script>`)
      printWindow.document.close()
      printWindow.focus()
      setTimeout(function() {
        printWindow.print()
        printWindow.close()
      }, 100)
    },
    group(array, subGroupLength) {
      let index = 0
      const newArray = []
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength))
      }
      return newArray
    },
    printT(row) {
      this.$api.MateriaklExportWarehouseDetails({
        DeliveryID: row.DeliveryId
      }).then(res => {
        console.log(res)
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        if (res.Message.Details.length <= 0) return this.$message.info('没有可打印的数据')
        // this.printTable = res.Message.Details
        this.printTableA(res.Message.Details)
      })
    },
    async  searchAd() {
      this.formSearch.PageIndex = 1
      this.formSearch.PageSize = this.tablePageSize
      if (this.formSearch.orderdate.length > 0) {
        this.formSearch.OrderStartDate = this.formSearch.orderdate[0]
        this.formSearch.OrderEndDate = this.formSearch.orderdate[1]
      }
      if (this.formSearch.outdate.length > 0) {
        this.formSearch.DeliveryStartDate = this.formSearch.outdate[0]
        this.formSearch.DeliveryEndDate = this.formSearch.outdate[1]
      }
      console.log(' this.formSearch', this.formSearch)
      this.setKeysData(this.formSearch, this.adSearchForm) // 展示搜索条件
      const response = await this.$api.QueryStockMaterialList(this.formSearch)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      this.formSearch.KeyWords = null
      this.isShow = false
    },
    Adsearch() {
      this.adSearchForm = []
      if (this.formSearch.SelectType === 1) {
        this.adSearchForm = this.adSearchForm1
      } else if (this.formSearch.SelectType === 2) {
        this.adSearchForm = this.adSearchForm1.concat([...this.adSearchForOthers])
      }
      this.isShow = false
      this.isShow = true
    },
    selectType(type) {
      this.visible = false
      this.currentPage = 1
      this.formSearch.SelectType = type
      this.getTableDataAsync()
      this.ExpressCompanyInfoLst()
      this.currentPage = 1
      this.$nextTick(() => { // 在数据加载完，重新渲染表格
        this.$refs.multipleTable.doLayout()
      })
    },
    async getTableDataAsync(page, pageSize) {
      this.formSearch.PageIndex = page || 1
      this.formSearch.PageSize = this.tablePageSize
      const response = await this.$api.QueryStockDeliveryList(this.formSearch)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      return response
    },
    // 出库记录
    deliveryRecord() {
      this.$router.push({
        name: 'delivery-record'
      })
    },
    // 其他出库
    otherDelivery() {
      this.$router.push({
        name: 'otherDelivery'
      })
    },
    // 发货
    EditOrderDeliveryStatus(row) {
      console.log(row)
      this.$router.push({
        name: 'delivery-fwarehouse',
        params: {
          StockOutType: 0,
          StockOutId: row.StockOutId
        }
      })
    }
  }
}

export default Index
