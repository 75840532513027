<template>
  <div class="delivery-goods">
    <PageHeader title="商品出库" />
    <div class="searchView flex a-center m-tb-10 j-between p-lr-20">
      <div class="flex">
        <el-popover
          v-if="$minCommon.checkMeau('spckview', userBottons)"
          v-model="visible"
          class="OrderDropDown"
          placement="bottom"
          trigger="hover"
          :width="80"
          popper-class="test_pop_view"
        >
          <div class="flex column">
            <div class="p-bottom-10 cursor" @click="selectType(1)">待出库</div>
            <div class="cursor" @click="selectType(2)">已完成</div>
          </div>
          <div slot="reference" class="out white bg p-lr-10 p-tb-5 min-border-right flex a-center f12 pointer" @click="visible = !visible">
            <div>{{ formSearch.SelectType === 1 ? '待出库' :'已完成' }}</div>
            <i class="el-icon-caret-bottom" />
          </div>
        </el-popover>
      </div>
      <!-- <div class="out p-lr-10 flex a-center m-lr-10 ModelBttton-white-30" @click="() => $refs.FieldManagement.openSetFieldManagement()">
        <i class="el-icon-setting f14 p-right-5" />   字段管理
      </div> -->
      <div v-if="$minCommon.checkMeau('spckview', userBottons)" class="flex SearchBox_30 m-left-10" style="flex: 1">
        <el-input
          v-model="formSearch.KeyWords"
          size="medium"
          placeholder="可通过出库单号、交货单号、销售订单号、终端/买方名称搜素"
          class="rule-input-edit"
        >
          <template slot="prepend">
            <div class="pointer" @click="Adsearch">
              <i class="el-icon-s-operation" />
              <span>高级搜索</span>
            </div>
          </template>
          <div
            slot="append"

            @click="searchGetTableDataAsync"
          >
            <span class="cursor">搜 索</span>

          </div>
        </el-input>
      </div>
      <div v-if="$minCommon.checkMeau('spckoutstocklog', userBottons)" class="out p-lr-10 min-border flex a-center f12 m-lr-10 cursor ModelBttton-white-30" @click="deliveryRecord">
        <i class="el-icon-tickets f15 p-right-5" />   出库记录
      </div>
      <div v-if="$minCommon.checkMeau('spckotheroutsotck', userBottons)" class="out p-lr-10 min-border flex a-center f12 cursor ModelBttton-white-30" @click="otherDelivery">
        <i class="el-icon-document f15 p-right-5" />   其他出库
      </div>
    </div>
    <SearchKeys v-if="isProductCjq" class="m-lr-20" :search-keys="searchKeys" @close="closeTag($event,formSearch,adSearchForm,['orderdate','outdate'],'OrderStartDate','OrderEndDate','DeliveryStartDate','DeliveryEndDate')" />
    <div class="p-lr-20">
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        :header-cell-style="headerCStyle"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        row-key="id"
        height="calc(100vh - 330px)"
        :border="true"
        @mouseleave.native="mouseLive"
        @cell-mouse-enter="mouseEnter($event,'StockOutId', 330, 30, $refs.multipleTable, tableData, 2 )"
        @cell-mouse-leave="mouseOut($event,'StockOutId')"
      >
        <template v-if="formSearch.SelectType === 1">
          <template v-for="(item,index) in tableListJson.deliveryGoods1">
            <el-table-column
              v-if="item.isChecked && item.prop !== 'OrderCreateDate' && item.prop !== 'BuyCompanyName' && item.prop !== 'DeliveryCreateDate'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              v-if="item.isChecked && item.prop === 'BuyCompanyName'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
              align="center"
            >
              <!-- BuyCompanyName   SendCompanyName -->
              <template slot-scope="scope">
                {{ scope.row.BuyCompanyName ||scope.row.SendCompanyName }}
              </template>
            </el-table-column>

            <el-table-column
              v-if="item.isChecked && item.prop === 'OrderCreateDate'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
              align="center"
            >
              <template slot-scope="scope">
                {{ $minCommon.formatDate1(scope.row.OrderCreateDate, 'yyyy-MM-dd hh:mm') }}
              </template>
            </el-table-column>
            <el-table-column
              v-if="item.isChecked && item.prop === 'DeliveryCreateDate'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
              align="center"
            >
              <template slot-scope="scope">
                {{ $minCommon.formatDate1(scope.row.DeliveryCreateDate, 'yyyy-MM-dd hh:mm') }}
              </template>
            </el-table-column>
          </template>
        </template>
        <el-table-column v-if="formSearch.SelectType === 1" id="set_del" align="center" label="操作">
          <template slot-scope="scope">
            <el-button v-if="scope.row.ckStatus !== 3 && scope.row.ckStatus !== 5 " size="mini" :type="(scope.row.ckStatus === 0 || scope.row.ckStatus === 1) ? 'success' :scope.row.ckStatus === 2 ? 'warning': 'success' " @click="viewDetails(scope.row,0)">
              {{ (scope.row.ckStatus === 0 || scope.row.ckStatus === 1) ? '确认发货' :scope.row.ckStatus === 2 ? '检验发货': '重新发货' }}
            </el-button>

            <el-button v-else size="mini" type="primary" @click="viewDetails(scope.row,0)">
              查看详情
            </el-button>
            <!--            <el-popover-->
            <!--              placement="left"-->
            <!--              trigger="hover"-->
            <!--              :width="100"-->
            <!--              popper-class="test_pop_view"-->
            <!--            >-->
            <!--              <div class="DropDownList">-->
            <!--                <div v-if="$minCommon.checkMeau('spckorder', userBottons)" class="OperationButton"> &lt;!&ndash; () => $router.push({name: 'view-order', params: scope.row }) &ndash;&gt;-->
            <!--                  <el-link :underline="false" @click="viewOrder(scope.row)"><i class="el-icon-view p-right-10" />查看订单</el-link>-->
            <!--                </div>-->
            <!--                <div v-if="$minCommon.checkMeau('spckprintoutstock', userBottons)" v-show="scope.row.StockOutId" class="OperationButton" @click="EditOrderDeliveryStatus(scope.row)">-->
            <!--                  <el-link :underline="false"><i class="el-icon-printer p-right-10" />打印出库单</el-link>-->
            <!--                </div>-->
            <!--                <div v-if="$minCommon.checkMeau('spckdelivergoods', userBottons)" v-show="scope.row.StockOutId" class="OperationButton" @click="EditOrderDeliveryStatus(scope.row)">-->
            <!--                  <span><el-link :underline="false"><i class="el-icon-truck p-right-10" />发货</el-link></span>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <i slot="reference" class="el-icon-more" />-->
            <!--            </el-popover>-->
          </template>
        </el-table-column>
        <template v-if="formSearch.SelectType === 2">
          <template v-for="(item,index) in tableListJson.deliveryGoods2">
            <el-table-column
              v-if="item.isChecked && item.prop !== 'OrderCreateDate' && item.prop !== 'BuyCompanyName' && item.prop !== 'StockOutId' && item.prop !== 'DeliveryCreateDate'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              align="center"
              show-overflow-tooltip
            />
            <el-table-column
              v-if="item.isChecked && item.prop === 'BuyCompanyName'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
              align="center"
            >
              <!-- BuyCompanyName   SendCompanyName -->
              <template slot-scope="scope">
                {{ scope.row.BuyCompanyName ||scope.row.SendCompanyName }}
              </template>
            </el-table-column>

            <el-table-column
              v-if="item.isChecked && item.prop === 'StockOutId'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.StockOutId }}
                <div v-if="chioceSetIndex === scope.$index" class="hoverDom flex j-end a-center">
                  <div class="hoverDomRow flex a-center j-end">
                    <!--   hoverInter    // #DDE8F2;  :class="isShowDomAnimate ? 'flex a-center hoverOut' : 'flex a-center hoverInter' "      -->
                    <div style="background-color: #DDE8F2;" class="flex a-center j-end hoverWidth" :class="isShowDomAnimate ? 'flex a-center hoverOut' : 'flex a-center hoverInter' ">
                      <div class="OperationButton">
                        <el-button size="mini" type="primary" @click="viewDetails(scope.row,0)">查看</el-button>
                      </div>
                      <div class="OperationButton m-lr-10">
                        <el-button size="mini" type="success" @click="printT(scope.row)">打印</el-button>
                      </div>
                      <div v-if="scope.row.ckStatus !== 5" class="OperationButton m-right-10">
                        <el-button size="mini" type="info" @click="WLshow(scope.row)">物流</el-button>
                      </div>
                    </div>
                  </div>

                </div>
                <div v-if="chioceSetIndex === scope.$index" id="clickBg" class=" flex a-center j-center">
                  <i style="background-color: #fff;padding: 5px" :class="isShowDomAnimate ? 'el-icon-d-arrow-left f14 cursor' : 'el-icon-d-arrow-right f14 cursor '" @click.stop="hideSetDom" />
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="item.isChecked && item.prop === 'OrderCreateDate'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
              align="center"
            >
              <template slot-scope="scope">
                {{ $minCommon.formatDate1(scope.row.OrderCreateDate, 'yyyy-MM-dd hh:mm') }}

              </template>
            </el-table-column>
            <el-table-column
              v-if="item.isChecked && item.prop === 'DeliveryCreateDate'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
              align="center"
            >
              <template slot-scope="scope">
                {{ $minCommon.formatDate1(scope.row.DeliveryCreateDate, 'yyyy-MM-dd hh:mm') }}
              </template>
            </el-table-column>
          </template>
        </template>

        <!--        <el-table-column v-if="formSearch.SelectType === 2" id="set_del" align="center" label="操作" fixed="right">-->
        <!--          <template />-->
        <!--        </el-table-column>-->

      </el-table>
      <div class="pagination-container flex j-between">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
        <!-- <el-button type="primary" icon="el-icon-printer" @click="print">打印</el-button> -->
      </div>
    </div>
    <Adsearch :form="formSearch" :ad-search-form="adSearchForm" :show="isShow" @cancel="(e) => isShow = e" @searchAd="searchAd" />
    <!-- 填写物流信息    start -->
    <el-dialog class="DepartmentBox" :close-on-click-modal="false" :visible.sync="visibleWL" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">填写物流信息</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => visibleWL = false" />
      </div>
      <div class="status_ flex j-start column a-center m-tb-30">
        <div class="form_view flex a-center j-start m-tb-10">
          <el-radio-group v-model="form.resource">
            <el-radio :label="3">自配送</el-radio>
            <el-radio :label="6">物流配送</el-radio>
          </el-radio-group>
        </div>
        <div v-if="form.resource === 6" id="select_c" class="form_view flex  j-start m-tb-10">
          <div class="name">物流公司：</div>
          <el-select v-model="nameWL" style="width: 100%" size="mini" placeholder="请选择物流公司">
            <el-option
              v-for="item in WLinfo"
              :key="item.ExpressNo"
              :label="item.ExpressName"
              :value="item.ExpressName"
            />
          </el-select>
          <div id="i_cjq">
            <el-input v-model="nameWL" placeholder="点击右侧箭头选择物流公司" style="height:100%" size="mini" />
          </div>
        </div>
        <div v-if="form.resource === 6" class="form_view flex a-center j-start m-tb-10">
          <div class="name">物流单号：</div>
          <el-input
            v-model="form.LogisticsCode"
            style="margin-left: 6px"
            size="mini"
            placeholder="请输入内容"
            clearable
          />
          <i class="el-icon-circle-plus-outline f25 m-left-10 cursor" style="color: #409EFF" @click="addCode" />
        </div>
        <div v-if="form.resource === 6" class="flex j-center column a-center" style="width: 100%">
          <div v-for="(item,index) in selects" :key="index" class="form_view flex a-center j-start m-tb-10">
            <div class="name">物流单号：</div>
            <el-input
              v-model="item.LogisticsCode"
              size="mini"
              style="margin-left: 6px"
              placeholder="请输入内容"
              clearable
            />
            <i class="el-icon-remove-outline f25 m-left-10 cursor" style="color: #409EFF" @click="delCode(index)" />
          </div>
        </div>
      </div>
      <div class="m-top-30 flex j-center m-bottom-20">
        <el-button @click="() => visibleWL = false">取消</el-button>
        <el-button type="primary" @click="WLadd">确认</el-button>
      </div>
    </el-dialog>
    <!-- 填写物流信息   end -->
    <!-- 字段搜索 -->
    <!-- <FieldManagement ref="FieldManagement" :select-type="formSearch.SelectType" action-name="QueryStockDeliveryList" :list="formSearch.SelectType === 1 ?tableListJson.deliveryGoods1 :tableListJson.deliveryGoods2 " :name="formSearch.SelectType === 1 ? 'deliveryGoods1' :'deliveryGoods2'" :change-list-table="changeListTable" /> -->
  </div>
</template>

<script>
import Index from '@/minxin/management/delivery-goods.js'
export default Index
</script>
<style scoped>
.el-table th.gutter {
display: table-cell;
}

</style>

<style scoped lang="scss">
@import "@/style/search.scss";
.el-table {
  /deep/tbody tr:hover>td {
    background-color:#DDE8F2;
  }
}
.header_view {
   box-shadow: 0 2px 12px rgba(0,0,0,0.1) ;
   border: 1px solid #EBEEF5;
   height: auto;
  .item_view {
    width: 33.3%;
    border-right: 1px dashed #ccc;
    .status {
      width: 100px;
    }
    .t {
      flex: 1;
      padding: 5px 0;
      font-size: 14px;
      .name {
        width: 100px !important;
        display: inline-block;
      }

    }
  }
}
.status_ {
  width: 100%;
  height: auto;
}
.form_view {
  width: 60%;
  //background-color: red;
  .name {
    width: 100px;
  }
}

#select_c {
  position: relative;
}
#i_cjq  ::v-deep {
    .el-input__inner {
      border: 0;
      height: 20px;
      margin-top: 5px;
      margin-left: 5px;
    }
  }
#i_cjq {
  position: absolute;
  left: 88px;
  top: 0;
  width: 50%;
  z-index: 700;
  border: none;
  height: 25px;
  overflow: hidden;
  display: flex;
  align-content: center;
}
</style>

